/**
 *
 * Patient Footer Component
 *
 */

import React from 'react'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { SITE_VARS } from '../../../lib/util.helper'
import imgLogo from '../../../images/assert-logo.png'

const Footer = () => (
  <div className="outer-container">
    <div className="inner-container footer-content">
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <img src={imgLogo} style={{maxWidth:235}} alt="logo"/>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
              <div className="footer-links">
                <a href="https://www.assertiotx.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a> <span className="link-separator">|</span>
                <a href="https://www.assertiotx.com/terms-of-use/" target="_blank" rel="noopener noreferrer">Terms of Use</a> <span className="link-separator">|</span>
                <a href="https://www.assertiotx.com/about/contact-us/" target="_blank" rel="noopener noreferrer">Contact Us</a> <span className="link-separator">|</span>
                <a href="https://www.assertiotx.com/" target="_blank" rel="noopener noreferrer">www.assertiotx.com</a>
              </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <p style={{textAlign:'center', color: 'white'}}>&copy; January {SITE_VARS.copyrightYear}. Assertio Therapeutics, Inc. Trademarks of Assertio Therapeutics, Inc. or its related companies include Assertio, the Assertio logo, ZIPSOR, and the ZIPSOR logo. <nobr>{SITE_VARS.jobcodePAT}</nobr> </p>
          </Col>
        </Row>
      </Grid>
    </div>
  </div>
)

export default Footer
