/**
 *
 * PATIENT Layout
 *
 */

import React, { Component, Fragment } from 'react'
import Helmet from 'react-helmet'
import { Grid } from 'react-flexbox-grid'
import posed, { PoseGroup } from 'react-pose'
import EventEmitter from '../../../lib/emitter'
import Header from '../header'
import Footer from '../footer'
import StickyISI from '../../sticky-isi'
import MobileNav from '../../mobile-nav'
import { SITE_VARS } from '../../../lib/util.helper'

import './reset.css'
import '../../../scss/hamburgers/hamburgers.scss'
import '../dfa-theme.scss'
import '../../../scss/indexpage.scss'

//Animation for the mobile//off canvas menu
const PosedAnimation = posed.div({
  enter: {
    y: 0,
    opacity: 1,
    delay: 0,
    transition: {
      y: { type: 'spring', stiffness: 800, damping: 20 },
      default: { duration: 100 },
    },
  },
  exit: {
    y: 50,
    opacity: 0,
    transition: { duration: 150 },
  },
})

let toggleMenuListener = null

class Layout extends Component {
  state = {
    showMobileMenu: false,
    footerHeight: 0,
    pageLoaded: false
  }

  //Toggle mobile/offcanvas menu
  toggleMenu = () => {
    this.setState(prevState => ({ showMobileMenu: !prevState.showMobileMenu }))
  }

  resize = () => {
    /**
     * Header height is calculated for top offset
     * Could be used to anchor to top if anchor links are used
     */
    // this.setState({
    //   headerHeight: this.headerRef.clientHeight,
    // })

    /**
     * If ISI is not needed remove the following state (footerHeight).
     * Also remove 'StickyISI' component call in this page.
     * Footer height is used to calculate bottomThreshold for sticky isi component
     */
    this.setState({
      footerHeight: this.footerRef.clientHeight,
    })
  }

  componentDidMount() {
    //Trigger resize on component load
    setTimeout(()=>{
      this.resize()
    },100)

    //Trigger resize on window resize
    window.addEventListener('resize', this.resize)

    this.setState({
      pageLoaded: true
    })
  }

  componentWillMount(){
    toggleMenuListener = EventEmitter.addListener('CLOSE_MOBILE_MENU', (data)=>{
      this.setState({
        showMobileMenu: false
      });
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
    toggleMenuListener && toggleMenuListener.remove();
  }

  render() {
    const { showMobileMenu, pageLoaded } = this.state

    return (
      <Fragment>
            <Helmet
              title={ SITE_VARS.sitename }
              meta={[
                {
                  name: 'description',
                  content: SITE_VARS.description
                },
                {
                  name: 'keywords',
                  content: SITE_VARS.keywords
                },
              ]}
            >
              <html lang="en" />
              <link rel="stylesheet" href="https://use.typekit.net/skj4xkh.css"></link>
              <link href="https://fonts.googleapis.com/css?family=Open+Sans:400,700" rel="stylesheet"></link>
              <script src="https://cdnjs.cloudflare.com/polyfill/v3/polyfill.min.js?features=es6&amp;flags=gated"></script>
            </Helmet>
            <div className="dfa-app">
              <header
                id="base-header"
                className="base-header"
                style={{ zIndex: this.state.showMobileMenu ? 0 : 1 }}
              >
                <Header />
              </header>
              <button
                className={`hamburger hamburger--elastic hamburger-icon ${(showMobileMenu && pageLoaded) && 'is-active'}`}
                type="button"
                aria-label="Menu"
                aria-controls="navigation"
                aria-expanded="true"
                onClick={this.toggleMenu}
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </button>
              {/*Body starts here*/}
              <div
                className="base-body"
              >
                <Grid fluid className={this.props.className}>{this.props.children}</Grid>
              </div>
              {/*Body ends here*/}
              <footer
                className="base-footer"
                ref={_footer => {
                  this.footerRef = _footer
                }}
              >

                {/*If ISI is not required the StickyISI component should be removed*/}
                <section id="isi">
                  <StickyISI FooterHeight={this.state.footerHeight} />
                </section>

                {/** ISI ends here */}

                <Footer />
              </footer>
              {/*Off Canvas menu starts here*/}
              <PoseGroup>
                {!showMobileMenu ? "" : [
                  <PosedAnimation key="anim1" className="posed-anim-wrapper">
                    <MobileNav />
                  </PosedAnimation>,
                ]}
              </PoseGroup>
              {/*Off Canvas menu ends here*/}
            </div>
          </Fragment>
    )
  }
}

export default Layout
