/**
 *
 * PATIENT Header Component
 *
 */

import { Link } from 'gatsby'
import React from 'react'
import { Grid, Row, Col } from 'react-flexbox-grid';
import SimpleNav from '../../simple-nav'
import logo from '../../../images/logo.png'

const Header = () => (
  <div className="outer-container">
    <div className="inner-container">
    <Grid fluid>
      <Row className="center-sm-only">
        <Col xs={12} sm={12} md={3}>
          <Link to="/" className="logo-wrap">
            <img src={logo} alt="Logo" className="logo"/>
          </Link>
        </Col>
        <Col xs={12} sm={12} md={9}>
          <SimpleNav className="desktop-nav"/>
        </Col>
      </Row>
    </Grid>

    </div>
  </div>
)

export default Header
