/**
 *
 * PATIENT ISI Component
 *
 */

import React from 'react'
import StickyFooter from 'react-sticky-footer'
import ISIContent from './isi-content'
import './isi-content.scss'

const isiHeight = 190

class StickyISI extends React.Component {
  state = {
    isiExpanded: false,
  }

  componentDidMount() {
    setTimeout(() => {
      window.location.href.indexOf('#isi') != -1 &&
        document.getElementById('isi').scrollIntoView(true)
    }, 200)
  }
  render() {
    return (
      <StickyFooter
        bottomThreshold={this.props.FooterHeight - isiHeight}
        normalStyles={{ backgroundColor: '#ffffff' }}
        stickyStyles={{
          backgroundColor: '#ffffff',
          width: '100%',
          height: `${isiHeight}px`,
          boxShadow: `-1px -3px 11px 0px rgba(132, 132, 132,0.2)`,
        }}
        onFooterStateChange={result => {
          this.setState({
            isiExpanded: result,
          })
        }}
      >
        <div className="isi-wrapper">
          <ISIContent isiExpanded={this.state.isiExpanded} />
        </div>
      </StickyFooter>
    )
  }
}
export default StickyISI
