/**
 *
 * PATIENT Simple Nav Component
 *
 */

import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import myEmitter from "../../lib/emitter"
import { withPrefix } from 'gatsby-link'
import { pushEventGA } from '../../lib/util.helper'

import './simple-nav.scss'

  /**
   * Refer to ./nav-config.js to update menu values.
   * Desktop menu specific styles are included in ./simple-nav.scss
   * */

const handleClick = (el) =>{
  myEmitter.emit("CLOSE_MOBILE_MENU", true);
  switch(el){
    case 'isi':
      pushEventGA('nav-pat','click','EXPAND FULL ISI')
      break;
    case 'pi':
      pushEventGA('nav-pat','click','DOWNLOAD PI')
      break;
    case 'med':
      pushEventGA('nav-pat','click','DOWNLOAD MED GUIDE')
      break;
    default:
      pushEventGA('nav-pat','click','')
  }
}

const SimpleNav = props => (
  <ul className={`main-nav ${props.className}`}>
    <li>
      <AnchorLink href="#isi" onClick={()=>{
          handleClick('isi')
        }}>
         IMPORTANT SAFETY INFORMATION
      </AnchorLink>
    </li>
    <li>
      <a href={withPrefix('/pdf/prescribing-information.pdf')} target="_blank" rel="noopener noreferrer" onClick={()=>{
          handleClick('pi')
        }}>PRESCRIBING INFORMATION</a>
    </li>
    <li>
      <a href={withPrefix('/pdf/medication-guide.pdf')} target="_blank" rel="noopener noreferrer" onClick={()=>{
          handleClick('med')
        }}>MEDICATION GUIDE</a>
    </li>
    <li>
      <a href="/hcp" onClick={()=>{
          handleClick()
        }}>HEALTHCARE PROVIDERS</a>
    </li>
  </ul>
)

export default SimpleNav