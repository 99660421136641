/**
 *
 * PATIENT ISI Content Component
 *
 */

import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { pushEventGA } from '../../lib/util.helper'

const ISIContent = props => (
  <div className="isi-content">
    <div className="inner-container">
      {!props.isiExpanded && (
        <AnchorLink
          href="#isi"
          className="isi-see-more"
          onClick={() => { pushEventGA('button','click','EXPAND FULL ISI(PAT)') }}
        >
          <span className="link-see-more">FULL SAFETY INFORMATION</span>

        </AnchorLink>
      )}
      <p>
        <strong>INDICATION</strong>
      </p>

      <p>
        ZIPSOR<sup>&reg;</sup> is a prescription Non-Steroidal Anti-Inflammatory
        Drug (NSAID). ZIPSOR is used for relief of mild to moderate pain in
        adults (18 years of age and older).
      </p>
      <div className="boxed-warning" style={{width: '40%'}}>
            <p style={{ textAlign: 'center', maxWidth: '99%' }}>
              <strong>
                WARNING: RISK OF SERIOUS CARDIOVASCULAR AND GASTROINTESTINAL
                EVENTS
                <br />
                <i>
                  See full prescribing information for complete boxed warning.
                </i>
              </strong>
            </p>
            <ul style={{ marginLeft: 0, paddingLeft: 20 }}>
              <li>
                Non-steroidal anti-inflammatory drugs (NSAIDs) cause an
                increased risk of serious cardiovascular thrombotic events,
                including myocardial infarction and stroke, which can be fatal.
                This risk may occur early in treatment and may increase with
                duration of use.
              </li>
              <li>
                ZIPSOR is contraindicated in the setting of coronary artery
                bypass graft (CABG) surgery.
              </li>
              <li>
                NSAIDs cause an increased risk of serious gastrointestinal (GI)
                adverse events including bleeding, ulceration, and perforation
                of the stomach or intestines, which can be fatal. These events
                can occur at any time during use and without warning symptoms.
                Elderly patients and patients with a prior history of peptic
                ulcer disease and/or GI bleeding are at greater risk for serious
                GI events.
              </li>
            </ul>
          </div>

          <p>
            <strong>IMPORTANT SAFETY INFORMATION</strong>
          </p>
          <p>
            <strong>
              Medication Guide for Nonsteroidal Anti-inflammatory Drugs (NSAIDs)
            </strong>
          </p>
          <p>
            <strong>
              What is the most important information I should know about
              medicines called Nonsteroidal Anti-inflammatory Drugs (NSAIDs)?
            </strong>
          </p>
          <p>
            <strong>NSAIDs can cause serious side effects, including:</strong>
          </p>

          <ul>
            <li>
              <strong>
                Increased risk of a heart attack or stroke that can lead to
                death.
              </strong>{' '}
              This risk may happen early in treatment and may increase:
              <ul>
                <li>with increasing doses of NSAIDs</li>
                <li>with longer use of NSAIDs</li>
                <li className="no-list-style more-padding">
                  <strong>
                    Do not take NSAIDs right before or after a heart surgery
                    called a “coronary artery bypass graft (CABG).”
                  </strong>
                </li>
                <li className="no-list-style">
                  <strong>
                    Avoid taking NSAIDs after a recent heart attack, unless your
                    healthcare provider tells you to. You may have an increased
                    risk of another heart attack if you take NSAIDs after a
                    recent heart attack.
                  </strong>
                </li>
              </ul>
            </li>
          </ul>
          <ul>
            <li>
              <strong>
                Increased risk of bleeding, ulcers, and tears (perforation) of
                the esophagus (tube leading from the mouth to the stomach),
                stomach and intestines:
              </strong>
              <ul>
                <li>anytime during use</li>
                <li>without warning symptoms</li>
                <li>that may cause death</li>
                <li className="no-list-style">
                  <strong>
                    The risk of getting an ulcer or bleeding increases with:
                  </strong>
                </li>
                <li>
                  past history of stomach ulcers, or stomach or intestinal
                  bleeding with use of NSAIDs
                </li>
                <li>
                  taking medicines called “corticosteroids”, “anticoagulants”,
                  “SSRIs”, or “SNRIs”
                </li>
                <li>increasing doses of NSAIDs</li>
                <li>longer use of NSAIDs</li>
                <li>smoking</li>
                <li>drinking alcohol</li>
                <li>older age</li>
                <li>poor health</li>
                <li>advanced liver disease</li>
                <li>bleeding problems</li>
              </ul>
            </li>
          </ul>
          <p style={{paddingBottom:0}}>
            <strong>NSAIDs should only be used:</strong>
          </p>
          <ul style={{marginTop: 0}} className="circle-list">
            <li>exactly as prescribed</li>
            <li>at the lowest dose possible for your treatment</li>
            <li>for the shortest time needed</li>
          </ul>
          <p>
            <strong>What are NSAIDs?</strong>
          </p>
          <p>
            NSAIDs are used to treat pain and redness, swelling, and heat
            (inflammation) from medical conditions such as different types of
            arthritis, menstrual cramps, and other types of short-term pain.
          </p>
          <p>
            <strong>Who should not take NSAIDs?</strong>
          </p>
          <p>
            <strong>Do not take NSAIDs:</strong>
          </p>
          <ul>
            <li>
              if you have had an asthma attack, hives, or other allergic
              reaction with aspirin or any other NSAIDs.
            </li>
            <li>right before or after heart bypass surgery.</li>
          </ul>
          <p>
            <strong>
              Before taking NSAIDS, tell your healthcare provider about all of
              your medical conditions, including if you:
            </strong>
          </p>
          <ul>
            <li>have liver or kidney problems</li>
            <li>have high blood pressure</li>
            <li>have asthma</li>
            <li>
              are pregnant or plan to become pregnant. Talk to your healthcare
              provivder if you are considering taking NSAIDs during pregnancy.{' '}
              <strong>
                You should not take NSAIDs after 29 weeks of pregnancy.
              </strong>
            </li>
            <li>are breastfeeding or plan to breast feed.</li>
          </ul>
          <p>
            <strong>
              Tell your healthcare provider about all of the medicines you take,
              including prescription or over-the-counter medicines, vitamins or
              herbal supplements.
            </strong>{' '}
            NSAIDs and some other medicines can interact with each other and
            cause serious side effects.{' '}
            <strong>
              Do not start taking any new medicine without talking to your
              healthcare provider first.
            </strong>
          </p>
          <p>
            <strong>What are the possible side effects of NSAIDs?</strong>
          </p>
          <p>
            <strong>NSAIDs can cause serious side effects, including:</strong>
          </p>
          <ul>
            <li style={{listStyle: 'none', marginLeft: '-18px'}}>
              <strong>
                See “What is the most important information I should know about
                medicines called Nonsteroidal Anti-inflammatory Drugs (NSAIDs)?
              </strong>
            </li>

            <li>new or worse high blood pressure</li>
            <li>heart failure</li>
            <li>liver problems including liver failure</li>
            <li>kidney problems including kidney failure</li>
            <li>low red blood cells (anemia)</li>
            <li>life-threatening skin reactions</li>
            <li>life-threatening allergic reactions</li>
            <li>
              <strong>Other side effects of NSAIDs include:</strong> stomach
              pain, constipation, diarrhea, gas, heartburn, nausea, vomiting,
              and dizziness.
            </li>
          </ul>
          <ul>
            <li style={{listStyle: 'none', marginLeft: '-18px'}}>
              <strong>
                Get emergency help right away if you get any of the following
                symptoms:
              </strong>
            </li>
            <li>shortness of breath or trouble breathing</li>
            <li>chest pain</li>
            <li>weakness in one part or side of your body</li>
            <li>slurred speech</li>
            <li>swelling of the face or throat</li>
          </ul>
          <ul>
            <li style={{listStyle: 'none', marginLeft: '-18px'}}>
              <strong>
                Stop taking your NSAID and call your healthcare provider right
                away if you get any of the following symptoms:
              </strong>
            </li>
            <li>nausea</li>
            <li>more tired or weaker than usual</li>
            <li>diarrhea</li>
            <li>itching</li>
            <li>your skin or eyes look yellow</li>
            <li>indigestion or stomach pain</li>
            <li>flu-like symptoms</li>
            <li>vomit blood</li>
            <li>
              there is blood in your bowel movement or it is black and sticky
              like tar
            </li>
            <li>unusual weight gain</li>
            <li>skin rash or blisters with fever</li>
            <li>swelling of the arms, legs, hands and feet</li>
          </ul>
          <p>
            <strong>
              If you take too much of your NSAID, call your healthcare provider
              or get medical help right away.
            </strong>
          </p>
          <p>
            These are not all the possible side effects of NSAIDs. For more
            information, ask your healthcare provider or pharmacist about
            NSAIDs.
          </p>
          <p>
            Call your doctor for medical advice about side effects. You may
            report side effects to FDA at 1-800-FDA-1088.
          </p>
          <p>
            <strong>Other information about NSAIDs</strong>
          </p>
          <ul>
            <li>
              Aspirin is an NSAID but it does not increase the chance of a heart
              attack. Aspirin can cause bleeding in the brain, stomach, and
              intestines. Aspirin can also cause ulcers in the stomach and
              intestines.
            </li>
            <li>
              Some NSAIDs are sold in lower doses without a prescription
              (over-the counter). Talk to your healthcare provider before using
              over-the-counter NSAIDs for more than 10 days.
            </li>
          </ul>
          <p>
            <strong>
              General information about the safe and effective use of NSAIDs
            </strong>
          </p>
          <p>
            Medicines are sometimes prescribed for purposes other than those
            listed in a Medication Guide. Do not use NSAIDs for a condition for
            which it was not prescribed. Do not give NSAIDs to other people,
            even if they have the same symptoms that you have. It may harm them.
          </p>
          <p>
            If you would like more information about NSAIDs, talk with your
            healthcare provider. You can ask your pharmacist or healthcare
            provider for information about NSAIDs that is written for health
            professionals.
          </p>
          <p style={{fontSize: '75%', lineHeight: '1.4em'}}>
            <strong>Distributed by:</strong> Depomed, Inc., Newark, CA 94560
            <br />
            For more information, go to{' '}
            <a href="https://www.ZIPSOR.com" target="_blank" rel="noopener noreferrer" style={{color: 'black', textDecoration: 'none'}}>
              www.ZIPSOR.com
            </a>{' '}
            or call 1-866-458-6389
          </p>
          <p style={{fontSize: '75%'}}>
            This Medication Guide has been approved by the U.S. Food and Drug
            Administration. Issued or Revised:
            <nobr>May 2016&nbsp; ZIP-001-C.5</nobr>
          </p>
          <div className="reference-block">
            <strong>REFERENCES</strong>: <strong>1.</strong> Centers for Disease
            Control and Prevention. Opioids for acute pain: what you need to
            know.{' '}
            <a href="https://www.cdc.gov/drugoverdose/pdf/patients/Opioids-for-Acute-Pain-a.pdf" target="_blank" rel="noopener noreferrer">
              https://www.cdc.gov/drugoverdose/pdf/patients/Opioids-for-Acute-Pain-a.pdf
            </a>
            . Accessed October 9, 2018.{' '}
            <nobr>
              <strong>2.</strong> Lissy
            </nobr>{' '}
            M, Scallion R, Stiff DD, Moore K. Pharmacokinetic comparison of an
            oral diclofenac potassium liquid-filled soft gelatin capsule with a
            diclofenac potassium tablet. <i>Expert Opin Pharmacother</i>.
            2010;11(5):701-708.{' '}
            <nobr>
              <strong>3.</strong> Cole
            </nobr>{' '}
            BE. Treating mild to moderate acute pain with oral diclofenac
            potassium liquid-filled capsules: rapid absorption with ProSorb
            dispersion technology. <i>Pain Medicine News</i>. 2011;March:1-8.{' '}
            <nobr>
              <strong>4.</strong> Riff
            </nobr>{' '}
            DS, Duckor S, Gottlieb I, et al. Diclofenac potassium liquid-filled
            soft gelatin capsules in the management of patients with
            postbunionectomy pain: a Phase III, multicenter, randomized,
            double-blind, placebo-controlled study conducted over 5 days.{' '}
            <i>Clin Ther</i>. 2009;31(10):2072-2085.{' '}
            <nobr>
              <strong>5.</strong> ZIPSOR.
            </nobr>{' '}
            [package insert]. Lake Forest, IL: Assertio Therapeutics,{' '}
            <nobr>Inc.; 2016.</nobr>
          </div>

    </div>
  </div>
)

export default ISIContent
